import './styles/style.css';

import React, { useState, useEffect,  useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles/style.css';
import io from 'socket.io-client';
import env from 'react-dotenv';

function Chat() {
  const userSession = useSelector(state => state.auth.userSession);
  const [socket, setSocket] = useState(null);

  const [isConnected, setIsConnected] = useState(false);
  const [lastPong, setLastPong] = useState(null);
  const [msg, setMsg] = useState('');
  const [receivedMsg, setReceivedMsg] = useState(null);
  const [received, setReceived] = useState([])

  const mark = useRef(new Date());
  const ellapsed = useRef(0);

  useEffect(() => {
    if (Object.keys(userSession).length !== 0) {
      console.log(userSession);
      setSocket(io(env.SOCKET_URL, {
        auth: {
          token: userSession.accessToken
        },
      }));
    }
  }, [userSession]);

  useEffect(() => {
    if (socket) {
      setupSocket();
    }
  }, [socket]);

  const setupSocket = () => {
      socket.on('connect', () => {
        setIsConnected(true);
      });

      socket.on('disconnect', () => {
        setIsConnected(false);
      });

      socket.on('pong', () => {
        setLastPong(new Date().toISOString());
      });

      socket.on('channel-0', (msg) => {
        ellapsed.curr = new Date() - mark; 
        setReceivedMsg(msg);
      });
  }

  useEffect(() =>{
    if (socket) {
      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('pong');
      };
    }
  }, [])

  useEffect(() => {
    if (receivedMsg) {
      setReceived([receivedMsg.msg, ... received]);
    }
  }, [receivedMsg]);

  const setMessage = (e) => {
    setMsg(e.target.value);
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    const toSend = msg;
    setMsg('');
    mark.curr = new Date();
    socket.emit('channel-0', { userId:0, msg: toSend });
  }

  const sendPing = () => {
    mark.curr = new Date();
    socket.emit('channel-0', 'pings');
  }

  return (
    <>
      { socket ? (
        <div>
          <h1>Chat</h1>
          <h4>Test chat by using the widget below</h4>
          <hr />
          <p>Connected: { '' + isConnected }</p>
          <p>RTT chat: { ellapsed.curr }</p>

          <div className="Chat-body">
            {received.map((str, idx)=>{return (<div className="Chat-row" key={idx}>{str}</div>)})}
          </div>
          <form onSubmit={handleSubmit}>
            <input type="text" onChange={(e) => setMessage(e)} value={msg} className="Chat-box"/>
          </form>
        </div>
      ) : <div>You shouldn't be here. :)</div> }
    </>
  )
}

export default Chat;