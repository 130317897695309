import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import userPool from './UserPool';

/**
 * User login to Cognito. 
 */
export function UserLogin(
    username, password, onSuccess, onFailure, newPasswordRequired) {
  var authenticationData = {
    Username: username,
    Password: password,
  };
  var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  var userData = {
    Username: username,
    Pool: userPool
  }
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: onSuccess,
    onFailure: onFailure,
    newPasswordRequired: newPasswordRequired,
  });
  return cognitoUser;
}

/**
 * New password request for login flow. 
 */
export function UserNewPassword(username, password, userAttributes, onSuccess, onFailure) {
  var userData = {
    Username: username,
    Pool: userPool
  }
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
    onSuccess: onSuccess,
    onFailure: onFailure
  });
  return cognitoUser;
}

/**
 * User signup to Cognito.
 */
export function UserSignup(
      email, username, password, cbHook) {
  var dataEmail = {
    Name: 'email',
    Value: email
  };
  var attributeList = [];
  attributeList.push(dataEmail);
  try {
    userPool.signUp(username, password, attributeList, null, cbHook);
  } catch (e) {
  }
}