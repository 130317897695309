import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userSession: {}
  },
  reducers: {
    putUserSession: (state, action) => {
      state.userSession = { ... state.userSession };
      state.userSession.idToken = action.payload.idToken;
      state.userSession.accessToken = action.payload.accessToken;
      state.userSession.refreshToken = action.payload.refreshToken;
    }
  }
})

export const { putUserSession } = authSlice.actions;
export default authSlice.reducer;