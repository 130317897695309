import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { putUserSession } from '../../../../../reducers/auth/authSlice';

import { UserLogin } from '../../../Cognito/User';

function LoginView(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.show) {
      setIsError(false);
      setIsLoading(false);
    }
  }, [props.show]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onLogin();
    }
  }

  const onLogin = () => {
    setIsError(false);
    setIsLoading(!isLoading);
    UserLogin(email, password,
      (result) => {
        console.log("success", result);
        dispatch(putUserSession({
          idToken: result.idToken.jwtToken,
          accessToken: result.accessToken.jwtToken,
          refreshToken: result.refreshToken.token
        }));
        props.onHide();
        navigate("/dashboard"); 
      },
      (error) => {
        setIsLoading(false);
        setIsError(true);
      },
      props.newPasswordRequired
    );
	}

  return (
    <>
      <Modal.Body className="no-border Login-modal-body">
        Gigachat is currently closed to the public. If you were assigned a login for alpha and beta testing, use your credentials below.
        { isError ? (
          <div className="Login-error-text">
            <FontAwesomeIcon icon={faCircleExclamation} />
            &nbsp;&nbsp;&nbsp;The provided login was incorrect. Please try again.
          </div>
        ) : '' }
        <div style={{height: "15px"}} />
        <div className="Login-form-label">Username</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="text" placeholder="" name="email" required className="Login-form-input" onChange={(e) => handleEmailChange(e)}/>
        <div style={{height: "12px"}} />
        <div className="Login-form-label">Password</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="password" placeholder="" name="password" required className="Login-form-input" onChange={(e) => handlePasswordChange(e)} />
        <div style={{height: "12px"}} />
      </Modal.Body>
      <Modal.Footer className="no-border Login-modal-footer" onClick={()=>{onLogin()}}>
        { !isLoading ? (
            <div className="Login-form-button">
                Log In
            </div>
        ) : (
            <div className="Login-form-loading">
              <FontAwesomeIcon icon={faCircleNotch} spin/>
            </div>
        )}
      </Modal.Footer>
    </>
  )
}

export default LoginView;