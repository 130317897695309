
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './domains/App';
import store from './app/store';
import { Provider } from 'react-redux';
import Chat from './domains/Chat';
import Dashboard from './domains/Dashboard';
import reportWebVitals from './reportWebVitals';
import "./index.css";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/chat",
    element: <Chat />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
