import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import { useSelector } from 'react-redux'

function DashboardMenu() {

  const userSession = useSelector((state) => state.userSession);

  return ( 
    <div className="Dashboard-menu">
      test
    </div>
  )
}

export default DashboardMenu;