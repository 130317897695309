import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import logo from './../../assets/gigachat.svg';
import { ReactComponent as CalendarIcon } from './../../assets/code-black-icon.svg';
import Header from './Header';
import LoginModal from '../Auth/Login/Modal';
import EmailForm from './EmailForm';

function App() {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/gigachatapp0.matomo.cloud/container_ceFMicrZ.js'; s.parentNode.insertBefore(g,s);
  }, [])

  return (
    <>
      <div className="App">
        <Header />
        <div className="App-body">
          <div className="App-body-splash-background">
            <div className="App-body-splash">
              <img className="App-body-logo" src={logo} />
              <div className="App-body-text">
                <div className="App-body-text-container">
                <div>Embed chat so you can</div>
                <div>
                  <span className="text-1">take back your community.&nbsp;</span>
                  <span className="text-2">stream everywhere.&nbsp;</span>
                  <span className="text-3">protect your brand.&nbsp;</span>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="App-body-eta-background">
            <div className="App-body-eta">
              <CalendarIcon className="calendar-icon" /><br />
              <div className="App-body-eta-title">
                Gigachat is still in development
              </div>
              <div className="App-body-eta-description">
                <p>
                  We're developing an embedded chat service for live streamers and content creators that want to decouple their communities from their platform.
                  We also want to learn more about you and your use case to potentially shape the feature set of our service.
                </p>
                <p>
                  Subscribe to our newsletter below and we'll be in contact.
                </p>
                <p>
                  <EmailForm />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="App-footer-background">
          <div className="App-footer">
            <div className="App-footer-left">
              <div>Gigachat.app</div>
              <div><a href="https://twitter.com/Lamb_gg">@Lamb_gg</a></div>
            </div>
            <div className="App-footer-right">
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
