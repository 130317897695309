import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import DashboardHeader from './DashboardHeader';
import DashboardMenu from './DashboardMenu';
import Chat from './DashboardBody/Chat/index.js';
import { useSelector } from 'react-redux'

function Dashboard() {

  const userSession = useSelector(state => state.auth.userSession);

  const [enabled, setEnabled] = useState(false);

  useEffect(()=> {
    if (userSession && Object.keys(userSession).length !== 0) {
      setEnabled(true);
    }
  }, [userSession]);

  return ( 
    <>
      { enabled ? (
        <div className="Dashboard-container">
          <DashboardHeader />
          <div className="Dashboard-body-container">
            <DashboardMenu />
            <div className="Dashboard-body">
              <div className="Dashboard-body-main">
                <Chat />
              </div>
            </div>
          </div>
        </div>
        ) : (<div style={{color: '#fff'}}>You shouldn't be here :)</div>)
      }
    </>
  )
}

export default Dashboard;