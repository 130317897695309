import Modal from 'react-bootstrap/Modal';

import React, { useState } from 'react';

import SignupView from './SignupView/index';
import { LoginViewState } from './constants/index';

import '../../styles/style.css';

function SignupModal(props) {
  const [userAttributes, setUserAttributes] = useState(null);

  const newPasswordRequired = (userAttributes, requiredAttributes) => {
    setUserAttributes(userAttributes);
  }

  return (
    <Modal
      {...props}
      backdropClassName="Login-modal-background no-border"
      contentClassName="Login-body no-border"
      dialogClassName="no-border"
      size="md"
      centered
    >
      <Modal.Header className="no-border Login-header">
        <Modal.Title id="contained-modal-title-vcenter" className="Login-header-text">
          <div>Sign up to Gigachat</div>
        </Modal.Title>
      </Modal.Header>
      <SignupView onHide={props.onHide} show={props.show} newPasswordRequired={newPasswordRequired} />
    </Modal>
  );
}

export default SignupModal;