import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import logo from '../../../assets/gigachat.svg';
import LoginModal from '../../Auth/Login/Modal';
import SignupModal from '../../Auth/Signup/Modal';
import store from '../../../app/store';
import { useSelector } from 'react-redux'

function Header() {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [signupModalShow, setSignupModalShow] = useState(false);

  const userSession = useSelector((state) => state.userSession);

  return ( 
    <div className="Header">
      <SignupModal
        show={signupModalShow}
        onHide={() => setSignupModalShow(false)}
      />
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
      <div className="Header-left">
        <div className="Header-logo">
          GIGACHAT
        </div>
      </div>
      <div className="Header-right">
        <div className="Header-right-menu">
          {
            !!userSession ?
              null : 
              (
                <>
                  <div className="Signup-button" onClick={() => setSignupModalShow(true)}>Sign Up</div>
                  <div className="Login-button" onClick={() => setLoginModalShow(true)}>Log In</div>
                </>
              )
          }
        </div>
      </div>
    </div>
  )
}

export default Header;