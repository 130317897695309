import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import { useSelector } from 'react-redux'

function DashboardHeader() {

  const userSession = useSelector((state) => state.userSession);

  const [page, setPage] = useState(0);

  const onMenuClick = (e) => {
    setPage(e);
  }

  return ( 
    <div className="Dashboard-top">
      <div className="Dashboard-header">
        <div className="Dashboard-header-left">
          <div className="Dashboard-header-logo">
            GIGACHAT
          </div>
          <div className="Dashboard-header-logo-slogan">
            beta
          </div>
        </div>
        <div className="Dashboard-header-right">
          <div className="Dashboard-header-right-menu">
              <div>test</div>
          </div>
        </div>
      </div>
      <div className="Dashboard-top-menu">
        <div className={page === 0 ? "Dashboard-top-menu-item-select" : "Dashboard-top-menu-item"} onClick={()=>{onMenuClick(0)}}>
          Home
        </div>
        <div className={page === 1 ? "Dashboard-top-menu-item-select" : "Dashboard-top-menu-item"} onClick={()=>{onMenuClick(1)}}>
          Settings
        </div>
        <div className={page === 3 ? "Dashboard-top-menu-item-select" : "Dashboard-top-menu-item"} onClick={()=>{onMenuClick(3)}}>
          Chat
        </div>
        <div className={page === 4 ? "Dashboard-top-menu-item-select" : "Dashboard-top-menu-item"} onClick={()=>{onMenuClick(4)}}>
          Emotes
        </div>
        <div className={page === 5 ? "Dashboard-top-menu-item-select" : "Dashboard-top-menu-item"} onClick={()=>{onMenuClick(5)}}>
          Code
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader;