import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';

function EmailForm() {

  const [emailInput, setEmailInput] = useState('');

  return ( 
    <div className="Email-Form">
      <div id="mc_embed_shell">
        <div id="mc_embed_signup">
            <form action="https://app.us11.list-manage.com/subscribe/post?u=ff5285d81572303eae031da97&amp;id=896a7bd3c6&amp;f_id=00f1a9e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
                <div id="mc_embed_signup_scroll" className="Email-Form-Signup-Container">
                    <div class="mc-field-group">
                      <label for="mce-EMAIL"></label>
                      <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" className="Email-Form-Input" onChange={e => setEmailInput(e.target.value)} value={emailInput} placeholder="Email"/>
                    </div>
                <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style={{}}></div>
                    <div class="response" id="mce-success-response" style={{}}></div>
                </div><div style={{'position': 'absolute', 'left': '-5000px'}} aria-hidden="true"><input type="text" name="b_ff5285d81572303eae031da97_896a7bd3c6" tabindex="-1" value="" /></div><div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe" className="Email-Form-Submit" /></div>
              </div>
        </form>
        </div>
      </div>
    </div>
  )
}

export default EmailForm;