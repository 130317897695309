import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { UserNewPassword } from '../../../Cognito/User';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { putUserSession } from '../../../../../reducers/auth/authSlice';

const ERROR_MSG_PASSWORDS_NOT_MATCHING = "Passwords do not match. Please try again.";
const ERROR_MSG_COGNITO_PASSWORD_FAILURE = "Failed to create new password. Please try again.";

function NewPasswordView(props) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [navigate] = useNavigate();
  const [dispatch] = useDispatch();

  useEffect(() => {
    console.log("User attributes", props.userAttributes);
  }, [props.userAttributes]);

  useEffect(() => {
    if (!props.show) {
      setIsError(false);
      setErrorMessage('');
      setIsLoading(false);
    }
  }, [props.show]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onNewPassword();
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  }

  const onNewPasswordSuccess = (result) => {
    console.log("success", result);
    dispatch(putUserSession({
      idToken: result.idToken.jwtToken,
      accessToken: result.accessToken.jwtToken,
      refreshToken: result.refreshToken.token
    }));
    props.onHide();
    navigate("/chat"); 
  }

  const onNewPasswordFailure = (error) => {
    setIsLoading(false);
    setIsError(true);
    setErrorMessage(ERROR_MSG_COGNITO_PASSWORD_FAILURE);
  }

  const onNewPassword = () => {
    if (password === confirmPassword) {
      setIsLoading(true);
      UserNewPassword(
        password, props.userAttributes, onNewPasswordSuccess, onNewPasswordFailure
      );
    } else {
      setIsError(true);
      setErrorMessage(ERROR_MSG_PASSWORDS_NOT_MATCHING);
    }
  }

  return (
    <>
      <Modal.Body className="no-border Login-modal-body">
        You're using an automatically generated password. Please create a new password:
        { isError ? (
          <div className="Login-error-text">
            <FontAwesomeIcon icon={faCircleExclamation} />
            &nbsp;&nbsp;&nbsp; {errorMessage}
          </div>
        ) : '' }
        <div style={{height: "15px"}} />
        <div className="Login-form-label">Password</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="password" placeholder="" name="password" required className="Login-form-input" onChange={(e) => handlePasswordChange(e)} />
        <div style={{height: "12px"}} />
        <div className="Login-form-label">Confirm password</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="password" placeholder="" name="confirmPassword" required className="Login-form-input" onChange={(e) => handleConfirmPasswordChange(e)} />
        <div style={{height: "12px"}} />
      </Modal.Body>
      <Modal.Footer className="no-border Login-modal-footer" onClick={()=>{onNewPassword()}}>
        { !isLoading ? (
          <div className="Login-form-button">
            Submit
          </div>
        ) : (
          <div className="Login-form-loading">
            <FontAwesomeIcon icon={faCircleNotch} spin/>
          </div>
        )}
      </Modal.Footer>
    </>
  )
}

export default NewPasswordView;