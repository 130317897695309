import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { SignupErrors } from '../constants';
import { putUserSession } from '../../../../../reducers/auth/authSlice';

import { UserSignup } from '../../../Cognito/User';

function SignupView(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(SignupErrors.NULL);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.show) {
      setIsError(false);
      setIsLoading(false);
    }
  }, [props.show]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSignup();
    }
  }

  const clearError = () => {
    setIsError(false);
    setErrorMessage(SignupErrors.NULL);
  }

  const validateFields = () => {
    if (confirmPassword !== password) {
      setIsError(true);
      setErrorMessage(SignupErrors.PASSWORD_MISMATCH);
      return false;
    }
    if (email.length === 0 || username.length === 0 || password.length === 0 || confirmPassword.length === 0) {
      setIsError(true);
      setErrorMessage(SignupErrors.EMPTY_FIELDS)
      return false;
    }
    return true;
  }

  const onSignup = () => {
    if (validateFields() === false) {
      return;
    }
    clearError();
    setIsLoading(!isLoading);
    
    UserSignup(email, username, password,
      (error, result) => {
        if (error) {
          setIsLoading(false);
          onSignupError(error);
        } else {
          setIsLoading(false);
          props.onHide();
        }
      });
	}

  const onSignupError = (error) => {
    console.log(error.code);
    if (error.code === 'InvalidPasswordException') {
      setIsError(true);
      setErrorMessage(SignupErrors.INVALID_PASSWORD);
    } else if (error.code === 'UsernameExistsException') {
      setIsError(true);
      setErrorMessage(SignupErrors.USERNAME_TAKEN);
    }
    return;
  }

  return (
    <>
      <Modal.Body className="no-border Login-modal-body">
        Gigachat is currently closed to the public, but you can still sign up now and we will contact you if your account is enabled for alpha or beta access.
        { isError ? (
          <div className="Login-error-text">
            <FontAwesomeIcon icon={faCircleExclamation} />
            &nbsp;&nbsp;&nbsp;{errorMessage}
          </div>
        ) : '' }
        <div style={{height: "15px"}} />
        <div className="Login-form-label">Email</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="text" placeholder="" name="email" required className="Login-form-input" onChange={(e) => handleEmailChange(e)}/>
        <div style={{height: "12px"}} />
        <div className="Login-form-label">Username</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="text" placeholder="" name="username" required className="Login-form-input" onChange={(e) => handleUsernameChange(e)}/>
        <div style={{height: "12px"}} />
        <div className="Login-form-label">Password</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="password" placeholder="" name="password" required className="Login-form-input" onChange={(e) => handlePasswordChange(e)} />
        <div style={{height: "12px"}} />
        <div className="Login-form-label">Confirm password</div>
        <input onKeyDown={handleKeyDown} readOnly={isLoading} type="password" placeholder="" name="confirmPassword" required className="Login-form-input" onChange={(e) => handleConfirmPasswordChange(e)} />
        <div style={{height: "12px"}} />
      </Modal.Body>
      <Modal.Footer className="no-border Login-modal-footer" onClick={()=>{onSignup()}}>
        { !isLoading ? (
            <div className="Login-form-button">
                Sign Up
            </div>
        ) : (
            <div className="Login-form-loading">
              <FontAwesomeIcon icon={faCircleNotch} spin/>
            </div>
        )}
      </Modal.Footer>
    </>
  )
}

export default SignupView;