import Modal from 'react-bootstrap/Modal';

import React, { useState } from 'react';

import LoginView from './LoginView/index';
import NewPasswordView from './NewPasswordView/index';
import { LoginViewState } from './constants/index';

import '../../styles/style.css';

function LoginModal(props) {
  const [loginState, setLoginState] = useState(LoginViewState.LOGIN);
  const [userAttributes, setUserAttributes] = useState(null);

  const newPasswordRequired = (userAttributes, requiredAttributes) => {
    setUserAttributes(userAttributes);
    setLoginState(LoginViewState.CONFIRM_NEW_PASSWORD);
  }

  return (
    <Modal
      {...props}
      backdropClassName="Login-modal-background no-border"
      contentClassName="Login-body no-border"
      dialogClassName="no-border"
      size="md"
      centered
    >
      <Modal.Header className="no-border Login-header">
        <Modal.Title id="contained-modal-title-vcenter" className="Login-header-text">
          <div>Log In to Gigachat</div>
        </Modal.Title>
      </Modal.Header>
      { loginState === LoginViewState.LOGIN ? (
        <LoginView onHide={props.onHide} show={props.show} newPasswordRequired={newPasswordRequired} />) : (<div />)
      }
      { loginState === LoginViewState.CONFIRM_NEW_PASSWORD ? (
        <NewPasswordView onHide={props.onHide} show={props.show} userAttributes={userAttributes} />
        ) : (<div />)
      }
    </Modal>
  );
}

export default LoginModal;